import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, catchError, filter, firstValueFrom, map, Observable, of, take, tap } from 'rxjs';

import { BuildsFilter } from '@@core/models/build/builds-filter.model';
import { LandingPageValue } from '@@core/models/landing-page.model';
import { UserPreference } from '@@core/models/user-preference.model';
import { APP_CONFIG } from '@@shared/providers/application-config-provider/application-config-provider.model';
import { ConfigurationStore } from '@@shared/stores/configuration-store/stores/configuration.store';
import { selectUserPreferencesState, UserPreferenceKey, UserPreferenceState } from '@@shared/user-preferences';

import { DashboardService } from '../../features/dashboard/services/dashboard.service';

@Injectable({
	providedIn: 'root'
})
export class UserPreferencesService {
	readonly buildsFilter$ = new BehaviorSubject<BuildsFilter>(null);
	readonly dashboardView$ = new BehaviorSubject<string>(null);

	readonly #apiBaseUrl = inject(APP_CONFIG).uri.apiBaseUrl;
	readonly #httpClient = inject(HttpClient);
	readonly #dashboardService = inject(DashboardService);
	readonly #router = inject(Router);
	readonly #landingPagesSignal$ = inject(ConfigurationStore).landingPagesSignal$;
	readonly #store = inject(Store);

	getPreference<T>(key: UserPreferenceKey): Observable<T> {
		const apiUrl = `${this.#apiBaseUrl}v2/user/preferences/${encodeURIComponent(key)}`;

		return this.#httpClient.get<UserPreference>(apiUrl)
			.pipe(
				map(response => response?.value as T)
			);
	}

	savePreference(key: UserPreferenceKey, value: string): Observable<any> {
		const apiUrl = `${this.#apiBaseUrl}v2/user/preferences/${encodeURIComponent(key)}`;
		const body = { value };

		return this.#httpClient.put<void>(apiUrl, body, { observe: 'response' });
	}

	clearPreference(key: UserPreferenceKey): Observable<any> {
		const apiUrl = `${this.#apiBaseUrl}v2/user/preferences/${encodeURIComponent(key)}`;

		return this.#httpClient.delete<void>(apiUrl, { observe: 'response' });
	}

	// FILTERS //
	loadDashboardFilter(): Promise<unknown> {
		return firstValueFrom(this.getPreference<string>(UserPreferenceKey.DashboardFilter).pipe(
			tap(response => {
				const filter = JSON.parse(response) as BuildsFilter;
				const cleanFilter = this.#dashboardService.removeOrphansFromDashboardFilter(filter);

				this.buildsFilter$.next(cleanFilter);
			}),
			catchError(() => {
				this.buildsFilter$.next({});

				return of(null);
			})));
	}

	saveDashboardFilter(filter: BuildsFilter): Promise<any> {
		return firstValueFrom(this.savePreference(UserPreferenceKey.DashboardFilter, JSON.stringify(filter))
			.pipe(tap(() => {
				this.buildsFilter$.next({ ...filter });
			})));
	}

	clearDashboardFilter(): Promise<any> {
		return this.clearPreference(UserPreferenceKey.DashboardFilter)
			.pipe(tap(() => {
				this.buildsFilter$.next({});
			})).toPromise();
	}

	// VIEWS //
	loadDashboardView(): Observable<string> {
		return this.getPreference<string>(UserPreferenceKey.DashboardView)
			.pipe(
				tap(viewId => this.dashboardView$.next(viewId)),
				catchError(() => of(null))
			);
	}

	saveDashboardView(viewId: string): Observable<any> {
		return this.savePreference(UserPreferenceKey.DashboardView, viewId)
			.pipe(
				tap(() => this.dashboardView$.next(viewId))
			);
	}

	clearDashboardView(): Observable<any> {
		return this.clearPreference(UserPreferenceKey.DashboardView)
			.pipe(
				tap(() => this.dashboardView$.next(null))
			);
	}
	// END OF VIEWS //

	goToDefaultLandingPage(): Observable<UserPreferenceState> {
		return this.#store.select(selectUserPreferencesState)
			.pipe(
				filter(store => store.loaded),
				take(1),
				tap(userPreferencesStore => {
					const landingPage = userPreferencesStore.values[UserPreferenceKey.LandingPage];
					const queryParams = this.#landingPagesSignal$()[landingPage]?.queryParams || {};
					void this.#router.navigate([this.#landingPagesSignal$()[landingPage]?.route || this.#landingPagesSignal$()[LandingPageValue.Dashboard].route], { queryParams });
				})
			);
	}
}
